// global

* {
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: $body-font;
  margin: 0;
  overflow-x: hidden;
  color: $body-text;
  font-weight: 300;
  background: $white;
}

html {
  position: relative;
  min-height: 100%;
  background: $white;
}

.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-drawer-container {
  background: $body-color;
}

html .page-wrapper {
  height: calc(100vh - 64px);

  .page-content {
    margin: 0 auto;
    padding: 15px 15px;
    // max-width: 1300px;
  }
}

// a tag

a:hover,
a:focus,
a {
  text-decoration: none;
}

a.link {
  color: $heading-text;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $themecolor;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

hr {
  border: 0px;
  height: 1px;
  background: $border;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: $danger;
  background-color: $light-danger;
  border-radius: 0.25rem;
}

.box {
  border-radius: $radius;
  padding: 10px;
}

.list-inline {
  margin-left: 0px;
  padding: 0px;

  li {
    display: inline-block;
    list-style: none;
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.button-row button {
  margin-bottom: 5px;
  margin-right: 5px;
}

html body {
  .position-relative {
    position: relative;
  }

  // list
  .clear {
    clear: both;
  }

  ol li {
    margin: 5px 0;
  }

  // display
  .dl {
    display: inline-block;
  }

  .db {
    display: block;
  }

  //   opacity
  .op-5 {
    opacity: 0.5;
  }

  .op-3 {
    opacity: 0.3;
  }

  .rounded {
    border-radius: $radius;
  }

  //   flex
  .ml-auto {
    margin-left: auto;
  }

  .d-flex {
    display: flex;
  }

  .align-items-center {
    align-items: center;
  }

  .no-shrink {
    flex-shrink: 0;
  }

  .oh {
    overflow: hidden;
  }

  .hide {
    display: none;
  }

  .img-circle {
    border-radius: 100%;
  }

  .radius {
    border-radius: $radius;
  }

  .no-shadow {
    box-shadow: none;
  }

  //   align vertical
  .vt {
    vertical-align: top;
  }

  .vm {
    vertical-align: middle;
  }

  .vb {
    vertical-align: bottom;
  }

  //   horizontal align
  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  //   border
  .b-0 {
    border: none;
  }

  .b-r {
    border-right: 1px solid $border;
  }

  .b-l {
    border-left: 1px solid $border;
  }

  .b-b {
    border-bottom: 1px solid $border;
  }

  .b-t {
    border-top: 1px solid $border;
  }

  .b-all {
    border: 1px solid $border;
  }
}

.mat-card {
  margin: 0 0 30px 0 !important;
}

.divider-box {
  display: block;
  margin: 20px 0;
}

.page-header {
  display: flex;
  justify-content: space-between;

  .btn {
    margin: 0 0 0 15px;
  }

  .mat-title {
    margin: 0;
  }
}

div.mat-expansion-panel-body {
  padding: 0 0 16px 15px;

  .mat-list-item a span {
    font-size: 13px;
  }
}

.d-none {
  display: none;
}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 18px !important;
  border-radius: 25px !important;
  line-height: 52px !important
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: $primary;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: $primary;
  color: white;
}

.mat-form-field-label {
  color: white !important;
}

.mat-select-value {
  color: white;
}


.container {
  padding-left: 35px;
  padding-right: 35px;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}

.btn-close {
  width: 40px;
  height: 40px;
  background: $secondary;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  float: right;
  margin-left: 15px;

  svg-icon {
    display: flex;
  }

  svg {
    stroke: $primary
  }
}



.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fff !important;
}
