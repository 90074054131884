/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0
}

.no-wrap{white-space: nowrap;}

@import 'front/variable';
@import 'front/theme';
@import 'front/fonts';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/grid';
@import 'front/mixins/font';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';

@import 'front/responsive';

/* MODAL */
.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, .32) !important;
    opacity: 1 !important;
  }
}

.cdk-drag.list-item {
  border-bottom: 1px solid #6b2394 !important;
}

.cdk-drop-list.list {
  border: 1px solid #6b2394 !important;
}

.position-absolute{
  position: absolute;
}

.position-relative{
  position: relative;
}

.text-align-center {
  text-align: center;
}

@media screen and (max-width: 1318px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 8px 24px !important;
    white-space: nowrap; //no break line
  }
}


.box-custom{
  display: flex;
  align-items: center;

  &-link{
    margin: 4px 4px 0;
    display: inline-block;
  }
}

.flex-row{
  flex-direction: row;
}

.gap-15{
  gap: 15px;
}

.gap-20{
  gap: 20px;
}

.mw-55{
  max-width: 55%;
}

.full-width-table {
  width: 100%;
}

.l-height-default{
  line-height: 31px;
}

.w-100{
  width: 100% !important;
}

.d-none{
  display: none !important;
}

.icon-home{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}


.main {
  min-height: 100vh;
  background-size: cover;

  &_filter {
    padding-top: 36px;
    min-height: inherit;
    background: rgba(23, 45, 77, 0.7);
    color: white;
  }
}

.mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgb(155 170 189) !important;
}

.mat-mini-fab {
  background-color: #898989;

  &.mat-accent {
    background-color: $secondary;
  }
}


.home {
  &_btn {
    width: 153px;
    height: 60px;
    border-radius: 30px;
    background: $secondary;

    @media (max-width: 600px) {
      width: 181px;
      height: 50px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: $primary;
    padding: 0 18px;

    &_schedule_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0.8);
    }
  }

  p {
    font-size: 18px;
    color: $white;
  }

  &_card {
    width: 100%;
    min-height: 649px;
    padding-top: 33px;
    padding-bottom: 95px;
    border-radius: 30px 30px 0 0;
    background: rgba(23, 45, 77, 0.88);

    &_technologies {
      background-color: transparent;
    }

    &-tech {
      padding-bottom: 33px;
    }

    &-thank {
      padding-bottom: 33px;
      border-radius: 30px;
    }

    &.description {
      background: linear-gradient(180deg, transparent 5%, #172D4D 35%) !important;
      min-height: 468px !important;
      border-radius: 0 0 30px 30px;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    &_frame {
      width: 100%;
      aspect-ratio: 16/9;
    }

    .mat-raised-button {
      width: 146px;
      min-height: 52px;
      height: 44px;
      text-align: left;
      padding: 0 35px;
      border-radius: 8px;
      background-color: #e4e4e4;
      font-weight: 100 !important;

      @media (max-width: 600px) {
        height: initial;
        white-space: normal;
        line-height: 1.2;
      }

      &.mat-accent {
        background-color: $secondary;
        font-weight: 500 !important;
      }
    }

    .mat-divider {
      border-top-color: black;
      margin: 26px 0;
    }


    &-white {
      background: white;
      color: #707070;

      .home_subtitle {
        font-size: 25px;
        color: $primary;
      }
    }
  }

  &_subtitle {
    font-weight: bold;
    font-size: 33px;
    color: $secondary;

    &.subtitle-small{
      font-size: 28px;
    }
  }

  &_description {
    font-size: 16px;
    margin-bottom: 37px;

    &-thanks {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }

  &_title {
    font-weight: bold;
    font-size: 38px;
    color: $white;
  }

  &_step {
    margin-bottom: 20px;
    width: 50%;
    @media(max-width: 600px){
      width: 100%;
    }
    &_icon {
      width: 73px;
      height: 73px;
      border-radius: 36.5px;
      background: $primary;
      border: 2px solid $secondary;

      display: flex;
      justify-content: center;
      align-content: center;

      padding: 17px;
    }


    &_text {
      font-weight: bold;
      font-size: 16px;
      line-height: 48px;
      color: #fff;
    }
  }

  &_quest {
    &_container {
      margin-bottom: 17px;

      &_number {
        font-size: 35px;
        line-height: 20px;
        color: $primary;
      }

      &_text {
        font-weight: bold;
        font-size: 16px;
        color: $primary;
      }
    }

    .mat-divider {
      border-top-color: black;
      margin: 26px 0;
    }
  }

  &_bar {
    position: fixed;
    width: 100%;
    max-width: 950px;

    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);

    &_btn {
      &_back-arrow {
        display: flex;
        transform: rotate(180deg);
        svg{
          stroke: #fff;
        }
      }
    }

    .mat-fab {
      width: 60px !important;
      height: 60px !important;
      background: #fcc002;
      border: 1px solid #ffe695;
      filter: drop-shadow(0px 3px 11px rgba(252, 192, 2, 0.52));

    }

    .mat-flat-button, .mat-button {
      width: 121px !important;
      height: 57px !important;
      position: relative;
    }

    .mat-flat-button {
      right: -20px;
    }

    .mat-button {
      left: -20px;
      backdrop-filter: blur(30px);
      --webkit-backdrop-filter: blur(30px);
      background-color: rgba(255, 255, 255, 0.15);

    }
  }
}
