/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

$body-font: 'Muller', sans-serif;
$heading-font: 'Muller', sans-serif;

$primary: #182e4c;
$secondary: #fcc202;

$body-color: #eef5f9;
$heading-text: #455a64;
$body-text: #535353;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;


$themecolor: #1e88e5;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;

//PALETTE
$mat-primary: (
  50: #e7eaef,
  100: #c0ccd9,
  200: #99aac0,
  300: #728aa6,
  400: #537194,
  500: #325985,
  600: #2b527d,
  700: #234872,
  800: #1e3e65,
  900: #182e4c,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
$mat-secondary: (
  50: #fef8e1,
  100: #feecb3,
  200: #fde082,
  300: #fcd64e,
  400: #fccb27,
  500: #fcc202,
  600: #fcb400,
  700: #fca100,
  800: #fd9000,
  900: #fd7000,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
